import type { PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import { logError } from '@/shared/utils/error'
import { NetworkError } from '@/shared/utils/errorClasses'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'
import { isEnergyElement, isMonthlyFeeElement } from '@/shared/utils/tariffElementUtils'

import { getDefaultStartDateByPriceType } from './utils/startDateUtils'
import {
  calcEstimatedMonthlyCost,
  getAddonsMonthlyDiscounts,
  getContractMonthlyDiscounts,
  getEnergyPriceSumWithDiscounts,
  sumPricesByType,
} from './utils/utils'

export type CostElement = {
  energy: CostDetails
  monthlyFee: CostDetails
}

export type CostDetails = {
  priceInclVat: number
  priceExclVat: number
  vatAmount: number
  priceUnit?: string | null
}

export type CalculateContractTemplateCostProps = {
  contractTemplate: EnrichedContractTemplate
  selectedAddonsTariffNos: number[]
  area?: PriceAreaCode
  estimatedYearlyConsumption?: number
  // DATE_FORMAT
  startDate?: string
}

export type ContractTemplateCost = {
  energyPriceSum: CostDetails
  energyPriceSumWithDiscounts: CostDetails
  monthlyFeeSum: CostDetails
  estimatedMonthlyCostWithDiscounts: CostDetails
  estimatedMonthlyCost: CostDetails
}

// TODO: Prepare calculation for all possible discounts duration units (month, weeks, days). Never seen other than month. No examples...
export const calculateContractTemplateCost = ({
  contractTemplate,
  area,
  estimatedYearlyConsumption,
  selectedAddonsTariffNos,
  startDate: propStartDate,
}: CalculateContractTemplateCostProps): null | ContractTemplateCost => {
  const { priceType, availableAddons, bundledAddons } = contractTemplate

  if (
    !area ||
    Number.isNaN(estimatedYearlyConsumption) ||
    //just to satisfy typescript
    estimatedYearlyConsumption === undefined ||
    estimatedYearlyConsumption === 0
  ) {
    return null
  }

  try {
    const startDate = propStartDate || getDefaultStartDateByPriceType(priceType)

    const uniqSelectedAddonsTariffNos = [...new Set(selectedAddonsTariffNos)]

    const selectedAddons = uniqSelectedAddonsTariffNos
      .map((addonTariffNo) => availableAddons.find((addon) => addon.tariffNo === addonTariffNo))
      .filter(isNotNullOrUndefined)

    const bundledAndSelectedAddons = [...selectedAddons, ...bundledAddons]

    const energyPriceSum = sumPricesByType(
      contractTemplate,
      bundledAndSelectedAddons,
      startDate,
      area,
      isEnergyElement,
    )

    const monthlyFeeSum = sumPricesByType(
      contractTemplate,
      bundledAndSelectedAddons,
      startDate,
      area,
      isMonthlyFeeElement,
    )

    //Price without discounts
    const estimatedMonthlyCost = calcEstimatedMonthlyCost(
      estimatedYearlyConsumption,
      energyPriceSum,
      monthlyFeeSum,
    )

    const addonsMonthlyDiscounts = getAddonsMonthlyDiscounts(selectedAddons)

    const contractMonthlyDiscounts = getContractMonthlyDiscounts(contractTemplate)

    const energyPriceSumWithDiscounts = getEnergyPriceSumWithDiscounts(
      energyPriceSum,
      addonsMonthlyDiscounts.energy,
      contractMonthlyDiscounts.energy,
    )

    const estimatedMonthlyCostWithDiscounts = calcEstimatedMonthlyCost(
      estimatedYearlyConsumption,
      energyPriceSum,
      monthlyFeeSum,
      contractMonthlyDiscounts,
      addonsMonthlyDiscounts,
    )

    //TODO: Think about adding spotPrice base one the grid area? If needed I think it should be exposed here.
    return {
      //total energy cost without discounts
      energyPriceSum,
      //total energy cost with discounts,
      energyPriceSumWithDiscounts,
      //TODO: Check is monthlyFeeSum is really needed if not remove it
      monthlyFeeSum,
      estimatedMonthlyCostWithDiscounts,
      estimatedMonthlyCost,
    }
  } catch (error) {
    const message = error instanceof Error ? error.message : 'Unknown error occurred'
    logError(new NetworkError(500, `Error calculating contract template cost: ${message}`))
    return null
  }
}
