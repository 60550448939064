import { DateTime } from 'luxon'
import { useTranslations } from 'next-intl'

import { Box, ContentText, fontWeights, spacing } from '@fortum/elemental-ui'

import { DATE_FORMAT_FI } from '@/open-web/utils/constants'
import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import type {
  PriceDetailsFieldsFragment,
  TariffElement,
} from '@/shared/graphql/schema/commonBackend/graphql'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'

import { StyledHeading } from '../../StyledHeading'
import {
  formatFinnishPrice,
  getActiveOrFutureMonthlyFee,
  getCurrentPrice,
  getFuturePrice,
} from '../utils'

export type AdditionalPricesProps = {
  nightPrices?: TariffElement
  dayPrices?: TariffElement
  winterPrices?: TariffElement
  otherSeasonPrices?: TariffElement
  monthlyFees?: TariffElement
  customerType?: EnrichedContractTemplate['customerType']
}

export const AdditionalPrices = ({
  nightPrices,
  dayPrices,
  winterPrices,
  otherSeasonPrices,
  monthlyFees,
  customerType,
}: AdditionalPricesProps) => {
  const t = useTranslations('purchaseFlow.priceBreakdown')
  const applicableMonthlyFee = getActiveOrFutureMonthlyFee(monthlyFees)

  const getFuturePriceLabel = (price: PriceDetailsFieldsFragment, element?: TariffElement) =>
    t('additionalPrices.energy.futurePrice', {
      date: DateTime.fromJSDate(new Date(price.fromDate)).toFormat(DATE_FORMAT_FI),
      monthlyFee: formatFinnishPrice(
        getPriceBaseOnCustomerType(applicableMonthlyFee ?? undefined, customerType),
        monthlyFees?.priceUnit ?? undefined,
      ),
      energyPrice: formatFinnishPrice(
        getPriceBaseOnCustomerType(price, customerType),
        element?.priceUnit ?? undefined,
      ),
    })

  const futureNightPrice = getFuturePrice(nightPrices?.pricesByArea?.FI)
  const futureDayPrice = getFuturePrice(dayPrices?.pricesByArea?.FI)
  const futureWinterPrice = getFuturePrice(winterPrices?.pricesByArea?.FI)
  const futureOtherSeasonPrice = getFuturePrice(otherSeasonPrices?.pricesByArea?.FI)

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <StyledHeading level={4} textAlign="center">
          {t('additionalPrices.nightPrice')}
        </StyledHeading>
        <Box display="flex">
          <ContentText fontWeight={fontWeights.bold}>
            {formatFinnishPrice(
              getPriceBaseOnCustomerType(
                getCurrentPrice(nightPrices?.pricesByArea?.FI),
                customerType,
              ),
              nightPrices?.priceUnit ?? undefined,
            )}
            &nbsp;
          </ContentText>
          <ContentText display="inline">{t('additionalPrices.energy.night')}</ContentText>
        </Box>
        {futureNightPrice && (
          <ContentText>{getFuturePriceLabel(futureNightPrice, nightPrices)}</ContentText>
        )}
      </Box>

      <Box display="flex" flexDirection="column" mt={spacing.xxs}>
        <Box display="flex">
          <ContentText fontWeight={fontWeights.bold}>
            {formatFinnishPrice(
              getPriceBaseOnCustomerType(
                getCurrentPrice(dayPrices?.pricesByArea?.FI),
                customerType,
              ),
              dayPrices?.priceUnit ?? undefined,
            )}
            &nbsp;
          </ContentText>
          <ContentText display="inline">{t('additionalPrices.energy.day')}</ContentText>
        </Box>
        {futureDayPrice && (
          <ContentText>{getFuturePriceLabel(futureDayPrice, dayPrices)}</ContentText>
        )}
      </Box>

      <Box display="flex" flexDirection="column" mt={spacing.xxs}>
        <StyledHeading level={4} textAlign="center">
          {t('additionalPrices.seasonalPrice')}
        </StyledHeading>
        <Box display="flex">
          <ContentText fontWeight={fontWeights.bold}>
            {formatFinnishPrice(
              getPriceBaseOnCustomerType(
                getCurrentPrice(winterPrices?.pricesByArea?.FI),
                customerType,
              ),
              winterPrices?.priceUnit ?? undefined,
            )}
            &nbsp;
          </ContentText>
          <ContentText>{t('additionalPrices.energy.winterDay')}</ContentText>
        </Box>
        {futureWinterPrice && (
          <ContentText>{getFuturePriceLabel(futureWinterPrice, winterPrices)}</ContentText>
        )}
      </Box>

      <Box display="flex" flexDirection="column" mt={spacing.xxs}>
        <Box display="flex">
          <ContentText fontWeight={fontWeights.bold}>
            {formatFinnishPrice(
              getPriceBaseOnCustomerType(
                getCurrentPrice(otherSeasonPrices?.pricesByArea?.FI),
                customerType,
              ),
              otherSeasonPrices?.priceUnit ?? undefined,
            )}
            &nbsp;
          </ContentText>
          <ContentText display="inline">{t('additionalPrices.energy.otherSeason')}</ContentText>
        </Box>
        {futureOtherSeasonPrice && (
          <ContentText>
            {getFuturePriceLabel(futureOtherSeasonPrice, otherSeasonPrices)}
          </ContentText>
        )}
      </Box>
    </Box>
  )
}
