import { calculateAddonCost } from '@/open-web/components/UpgradeFlow/utils'
import type { ContractTemplateCost } from '@/open-web/services/calculators/calculateContractTemplateCost'
import { calculateContractTemplateCost } from '@/open-web/services/calculators/calculateContractTemplateCost'
import { getDefaultStartDateByPriceType } from '@/open-web/services/calculators/utils/startDateUtils'
import {
  GTM_CONTRACT_DEFAULT_MAIN_CATEGORY,
  SMART_PACKAGE_CONTRACT_ATTRIBUTE,
} from '@/open-web/utils/constants'
import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import { getActiveContractTemplate } from '@/open-web/utils/purchaseFlowUtils'
import type { MonthlyFeeElement } from '@/shared/components/PriceBreakdown/types'
import { getMonthlyFeeElements } from '@/shared/components/PriceBreakdown/utils'
import type { CampaignConfigurationEntry } from '@/shared/contentful/types'
import { browserEnvs } from '@/shared/envs'
import type { PriceAreaCode, PriceType } from '@/shared/graphql/schema/commonBackend/graphql'
import { getActiveCampaignConfig } from '@/shared/sections/ContractListSection/utils'
import type {
  EnrichedAvailableAddon,
  EnrichedContractProduct,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import type { DeliveryAddressType } from '@/shared/store/slices/checkoutFlowSlice'

import type { AddonItem } from '../addonsUtils'

type PriceElements = {
  energyPriceElements: ContractTemplateCost | null
  monthlyFeeElements: MonthlyFeeElement[]
}

export const useDeliveryInfo = () => {
  const { deliveryArea, getEstimatedConsumption } = useGlobalStore((store) => store.housingInfo)
  const deliveryStartDate = useGlobalStore((store) => store.checkoutData.delivery.startDate)
  return { deliveryArea, deliveryStartDate, estimatedConsumption: getEstimatedConsumption() }
}

export const getEnrichedContractTemplates = (
  contractProducts: EnrichedContractProduct[],
  defaultCampaignId: number | undefined | null,
  campaignsConfigurations: CampaignConfigurationEntry[] | undefined,
  estimatedConsumption: number | undefined,
): EnrichedContractTemplate[] => {
  return contractProducts.map((contractProduct) => {
    const campaignConfiguration = getActiveCampaignConfig(
      defaultCampaignId || contractProduct.cfData.defaultCampaignId,
      campaignsConfigurations,
    )
    return {
      ...getActiveContractTemplate(
        contractProduct,
        campaignConfiguration.campaignId,
        estimatedConsumption,
      ),
    }
  })
}

export const getPriceElements = (
  contractTemplate: EnrichedContractTemplate,
  area: PriceAreaCode | undefined,
  estimatedYearlyConsumption: number | undefined,
  selectedAddons: number[],
  deliveryStartDate: string | undefined,
) => {
  const startDate = deliveryStartDate || getDefaultStartDateByPriceType(contractTemplate.priceType)

  const monthlyFeeElements = getMonthlyFeeElements(
    contractTemplate,
    selectedAddons,
    startDate,
    area,
  )
  return {
    energyPriceElements: calculateContractTemplateCost({
      contractTemplate,
      area,
      estimatedYearlyConsumption,
      selectedAddonsTariffNos: selectedAddons,
      startDate,
    }),
    monthlyFeeElements,
  }
}

export const getContractProductsAndPrices = (
  contractProducts: EnrichedContractProduct[],
  defaultCampaignId: number | undefined | null,
  campaignsConfigurations: CampaignConfigurationEntry[] | undefined,
  estimatedConsumption: number | undefined,
  deliveryStartDate: string | undefined,
  deliveryArea: PriceAreaCode | undefined,
) => {
  const enrichedContractTemplates = getEnrichedContractTemplates(
    contractProducts,
    defaultCampaignId,
    campaignsConfigurations,
    estimatedConsumption,
  )

  const products = enrichedContractTemplates.map((enrichedContractTemplate) => {
    return {
      contractProduct: enrichedContractTemplate,
      priceElements: getPriceElements(
        enrichedContractTemplate,
        deliveryArea,
        estimatedConsumption,
        [],
        deliveryStartDate,
      ),
    }
  })

  return products
}

export const mapContractItemToGTM = (
  data: EnrichedContractTemplate,
  priceElements: PriceElements,
  delivery: DeliveryAddressType | undefined | null,
  customerType: string,
) => {
  const energyPrice =
    getPriceBaseOnCustomerType(
      priceElements.energyPriceElements?.estimatedMonthlyCostWithDiscounts,
      customerType,
    ) || 0
  const monthlyFee = priceElements.monthlyFeeElements.find(
    (element) => element.key === 'monthlyFee',
  )

  const priceWithoutDiscount =
    getPriceBaseOnCustomerType(monthlyFee?.priceWithoutDiscount, customerType) || 0

  const priceWithDiscount =
    getPriceBaseOnCustomerType(monthlyFee?.priceWithDiscount, customerType) || 0

  const discount = priceWithoutDiscount - (priceWithDiscount ?? priceWithoutDiscount)

  const itemCategory2 = getProductItemCategory2(data.priceType, data.productAttributes)

  return {
    item_id: data.tariffNo,
    item_name: data.productName,
    coupon: data.cfData.badge?.title ?? undefined,
    item_brand: `fortum ${browserEnvs.NEXT_PUBLIC_COUNTRY}`,
    item_category: GTM_CONTRACT_DEFAULT_MAIN_CATEGORY,
    item_category2: itemCategory2,
    item_category3: delivery || undefined,
    item_variant: data.cfData.name,
    quantity: 1,
    price: getPriceBaseOnCustomerType(
      priceElements.energyPriceElements?.monthlyFeeSum,
      customerType,
    ),
    price_per_usage: (energyPrice / 100).toFixed(2),
    discount: discount,
    discount_period: monthlyFee?.discountDuration,
    affiliation: customerType,
  }
}

const addonFees = (
  addon: EnrichedAvailableAddon | AddonItem,
  customerType: string,
): [number, number, string] => {
  if ('templateName' in addon) {
    const energyDiscountElement = calculateAddonCost(addon)
    const monthlyFee =
      getPriceBaseOnCustomerType(energyDiscountElement.monthlyFee, customerType) || 0
    const monthlyFeeWithDiscount =
      getPriceBaseOnCustomerType(energyDiscountElement.monthlyFeeWithDiscount, customerType) || 0
    const pricePerUsage = (
      getPriceBaseOnCustomerType(energyDiscountElement.energy, customerType) || 0 / 100
    ).toFixed(2)

    return [monthlyFee, monthlyFeeWithDiscount, pricePerUsage]
  }
  const monthlyFee = addon?.monthly?.price || 0
  const monthlyFeeWithDiscount = addon?.monthly?.priceWithDiscount || 0
  const pricePerUsage = (addon.energy?.price || 0 / 100).toFixed(2)

  return [monthlyFee, monthlyFeeWithDiscount, pricePerUsage]
}

export const mapSingleAddonToGTM = (
  addon: EnrichedAvailableAddon | AddonItem,
  customerType: string,
  delivery?: DeliveryAddressType,
) => {
  const [monthlyFee, monthlyFeeWithDiscount, pricePerUsage] = addonFees(addon, customerType)
  const discount = monthlyFee - monthlyFeeWithDiscount
  const isEnrichedAddon = 'templateName' in addon
  const energyDiscountElement = isEnrichedAddon ? calculateAddonCost(addon) : undefined

  return {
    item_id: addon.tariffNo,
    item_name: isEnrichedAddon ? addon.productName : addon.primaryText,
    coupon: undefined,
    item_brand: `fortum ${browserEnvs.NEXT_PUBLIC_COUNTRY}`,
    item_category: isEnrichedAddon ? (monthlyFee !== 0 ? 'vas' : 'basic service') : 'VAS',
    item_category2: isEnrichedAddon ? addon.addonType : undefined,
    item_category3: delivery,
    item_variant: isEnrichedAddon ? undefined : addon.contentName,
    price: monthlyFee,
    price_per_usage: pricePerUsage,
    discount: discount,
    discount_period: isEnrichedAddon
      ? energyDiscountElement?.monthlyFeeDiscountDuration?.amount
      : addon.monthly?.discountDuration,
    quantity: 1,
    affiliation: customerType,
  }
}

export const mapAddonToGTM = (
  addons: (EnrichedAvailableAddon | AddonItem | undefined)[],
  customerType: string,
  delivery?: DeliveryAddressType,
) => {
  return addons
    .filter((addon): addon is EnrichedAvailableAddon | AddonItem => !!addon) // Filter out undefined values
    .map((addon) => mapSingleAddonToGTM(addon, customerType, delivery))
}

export const mapContractAndAddons = (
  contractTemplate: EnrichedContractTemplate,
  selectedAddonsTariffNos: number[],
  priceElements: PriceElements,
  delivery: DeliveryAddressType | undefined,
  customerType: string,
) => {
  const addonList = selectedAddonsTariffNos.map((tariffNo) => {
    return contractTemplate.availableAddons.find((addon) => addon.tariffNo === tariffNo)
  })

  const mappedContractItem = mapContractItemToGTM(
    contractTemplate,
    priceElements,
    delivery,
    customerType,
  )
  const mappedAddon = mapAddonToGTM(addonList, customerType, delivery).filter(
    (item) => item !== undefined,
  )

  return [mappedContractItem, ...mappedAddon].filter(Boolean)
}

export const getCurrency = (priceUnit: PriceAreaCode | undefined) => {
  if (priceUnit === 'FI') {
    return 'EUR'
  }
  if (priceUnit?.includes('SE')) {
    return 'SEK'
  }
  if (priceUnit?.includes('NO')) {
    return 'NOK'
  }
  return
}

const getProductItemCategory2 = (priceType: PriceType, productAttributes?: string[] | null) => {
  if (productAttributes?.includes(SMART_PACKAGE_CONTRACT_ATTRIBUTE)) {
    return `${priceType}_VALUE_PACKAGE`
  }
  return priceType
}
