/**
 * TODO: Revisit this component's neccessity on cleanup phase.
 */
import type { CommonBoxProps, FontSize } from '@fortum/elemental-ui'
import { Box, spacing } from '@fortum/elemental-ui'

import type { PriceItemProps } from './PriceItem'
import { PriceItem } from './PriceItem'

type PriceSectionProps = {
  /**
   * List of prices to present
   */
  priceItems: PriceItemProps[]

  /**
   * Set custom color
   */
  colors?: ColorProps<never, 'textPrimary' | 'textPositive' | 'textSecondary'>
  /**
   * Wrapper props
   */
  boxProps?: CommonBoxProps
  /**
   * Price Item font size
   */
  priceItemFontSize?: FontSize
  /**
   * Original price font size
   */
  priceStrikeThroughSize?: FontSize
}

/**
 * Section with list of price items
 */
export const PriceSection = ({
  colors = {},
  priceItems = [],
  boxProps,
  priceItemFontSize,
  priceStrikeThroughSize,
}: PriceSectionProps) => {
  const textDirection = (boxProps?.alignContent ?? 'right') as 'left' | 'right' | 'center'
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing.xxxs}
      data-testid="price-section"
      {...boxProps}
      textAlign={textDirection}
    >
      {priceItems.map((priceItem, index) => (
        <PriceItem
          headingSize={priceItemFontSize}
          priceStrikeThroughSize={priceStrikeThroughSize}
          key={index}
          {...priceItem}
          colors={colors}
          alignContent={textDirection}
        />
      ))}
    </Box>
  )
}
