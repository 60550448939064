'use client'

import { useEffect } from 'react'

import { Box, ContentText, IconError, Section, spacing } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'
import { logError } from '@/shared/utils/error'
import { SectionError as SectionDisplayError } from '@/shared/utils/errorClasses'

type Props = {
  message: string
  section?: string
}

/**
 * A component to show in place of a section if there is an error in the data and the section cannot be rendered.
 */
export const SectionError = ({ message, section }: Props) => {
  const theme = useTheme()
  //Should be removed once each section is passed to the component
  section = section || 'unknown'

  useEffect(() => {
    logError(new SectionDisplayError(section, message))
  }, [message, section])

  return (
    <Section>
      <Box
        paddingHorizontal={spacing.s}
        paddingVertical={spacing.xs}
        borderRadius={spacing.xxxs}
        background={theme.colors.backgroundWarning}
        textColor={theme.colors.textWarning}
        display="flex"
        alignItems="center"
        gap={spacing.xxs}
      >
        <IconError />
        <ContentText color="inherit">{message}</ContentText>
      </Box>
    </Section>
  )
}
