import type { CampaignConfigurationEntry } from '@/shared/contentful/types'
import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'

export const getActiveCampaignConfig = (
  campaignId?: number | null,
  campaignsConfigurations?: CampaignConfigurationEntry[],
) => {
  const campaignConfig = campaignsConfigurations?.find((c) => c?.campaignId === campaignId)
  if (campaignConfig) {
    return campaignConfig
  }
  throw logError(new UiError(`Can't find configuration for campaignId: ${campaignId}`))
}
