import { DateTime } from 'luxon'

import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import type { PriceDetails } from '@/shared/graphql/queries/queryTypes'
import type {
  ContractDiscountElement,
  TariffElement,
} from '@/shared/graphql/schema/commonBackend/graphql'
import type { Theme } from '@/shared/providers/theme'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import { logError } from '@/shared/utils/error'
import { ContractOrderError } from '@/shared/utils/errorClasses'

import type { PriceProps } from './types'

export const defineTheme = (colors: Theme['colors'], theme: PriceProps['theme'] = 'light') => {
  switch (theme) {
    case 'light':
      return {
        title: colors.textPrimary,
        price: colors.textPrimary,
        crossedPrice: colors.textSecondary,
        detailedPrice: colors.textPrimary,
        noPriceInfo: colors.textPositive,
      }

    case 'dark':
      return {
        title: colors.textLightOnDark,
        price: colors.textLightOnDark,
        crossedPrice: colors.textLightOnDark,
        detailedPrice: colors.textLightOnDarkAccent,
        noPriceInfo: colors.textLightOnDarkAccent,
      }
  }
}

export const validateCustomerType = (contractTemplate: EnrichedContractTemplate) => {
  if (
    contractTemplate.customerType !== 'ENTERPRISE' &&
    contractTemplate.customerType !== 'PRIVATE'
  ) {
    throw logError(
      new ContractOrderError(
        `${contractTemplate.templateNo} has UNKNOWN customerType. Contract has bad configuration. Can not be sold!`,
      ),
    )
  }
}

export const formatFinnishPrice = (
  price: number | string | null | undefined,
  unit?: string,
): string => {
  if (price == null) {
    return '--'
  }

  const formattedPrice = Number(price).toLocaleString('fi-FI', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return unit ? `${formattedPrice} ${unit}` : formattedPrice
}

export const getDiscountedPrice = (
  price: number,
  discountElement: ContractDiscountElement | undefined,
  customerType: string,
): number => {
  const discountElementPrice = discountElement?.prices
    ? getPriceBaseOnCustomerType(discountElement.prices[0], customerType)
    : 0
  return discountElement ? price + (discountElementPrice || 0) : price
}

export const getCurrentPrice = (prices?: PriceDetails[] | null) => {
  const today = DateTime.now()
  return prices?.find(
    (price) =>
      DateTime.fromISO(price.fromDate) <= today &&
      price.toDate &&
      DateTime.fromISO(price.toDate) > today,
  )
}

export const getFuturePrice = (prices?: PriceDetails[] | null) => {
  const today = DateTime.now()
  return prices?.find((price) => DateTime.fromISO(price.fromDate) > today) || null
}

export const getActiveOrFutureMonthlyFee = (monthlyFees?: TariffElement): PriceDetails | null => {
  if (!monthlyFees) {
    return null
  }
  const futureMonthlyFee = getFuturePrice(monthlyFees.prices ?? [])
  const currentMonthlyFee = getCurrentPrice(monthlyFees.prices ?? [])

  return futureMonthlyFee || currentMonthlyFee!
}
