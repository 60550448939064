import type { ContractTemplateCost } from '@/open-web/services/calculators/calculateContractTemplateCost'
import { getValidPriceForDate } from '@/open-web/services/calculators/utils/utils'
import type {
  ContractDiscountElement,
  DiscountElement,
  PriceAreaCode,
  TariffElement,
} from '@/shared/graphql/schema/commonBackend/graphql'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import type { SelectedAddons } from '@/shared/store/slices/selectedContractSlice'
import { sumPrices } from '@/shared/utils/contractTemplateUtils'
import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'
import {
  SEASON_ENERGY_PRICE_ELEMENTS,
  isDiscountForElement,
  isEnergyDiscountElement,
  isEnergyElement,
  isMonthlyFeeElement,
} from '@/shared/utils/tariffElementUtils'

import type { EnergyPriceElement, MonthlyFeeElement } from './types'

const getContractEnergyPriceElements = (
  contractTemplate: EnrichedContractTemplate,
  startDate: string,
  area: PriceAreaCode,
): EnergyPriceElement[] => {
  const { tariffElements, discountElements } = contractTemplate

  const contractEnergyElements = tariffElements.filter(
    (tariffEl) =>
      isEnergyElement(tariffEl.type) && !SEASON_ENERGY_PRICE_ELEMENTS.includes(tariffEl.type),
  )

  const contractEnergyDiscountElements = discountElements.filter((el) =>
    isEnergyDiscountElement(el.type),
  )

  const energyElements: (ContractDiscountElement | TariffElement)[] = [
    ...contractEnergyElements,
    ...contractEnergyDiscountElements,
  ]

  return energyElements
    .map((el) => {
      const price = getValidPriceForDate(startDate, el.prices || el.pricesByArea?.[area])
      return price
        ? {
            price,
            priceUnit: el.priceUnit || '',
            elementName: el.elementName,
            key: el.type,
            duration: 'duration' in el && el.duration ? el.duration : undefined,
          }
        : null
    })
    .filter(Boolean)
    .sort((a, b) => Math.abs(b.price.priceExclVat) - Math.abs(a.price.priceExclVat))
}

const getAddonEnergyPriceElements = (
  contractTemplate: EnrichedContractTemplate,
  selectedAddons: number[],
  startDate: string,
  area: PriceAreaCode,
): EnergyPriceElement[] => {
  const { bundledAddons, availableAddons } = contractTemplate

  const bundledAddonsEnergyElements = bundledAddons.flatMap((addon) =>
    [
      ...(addon.tariffElements?.filter((el) => isEnergyElement(el.type)) || []),
      ...(addon.discountElements?.filter((el) => isEnergyDiscountElement(el.type)) || []),
    ]?.map((el) => addKey(addon.addonType, el)),
  )
  const availableAddonsEnergyElements = availableAddons
    .filter((addon) => selectedAddons.includes(addon.tariffNo))
    .flatMap((addon) =>
      [
        ...(addon.tariffElements?.filter((el) => isEnergyElement(el.type)) || []),
        ...(addon.discountElements?.filter((el) => isEnergyDiscountElement(el.type)) || []),
      ]?.map((el) => addKey(addon.addonType, el)),
    )

  return bundledAddonsEnergyElements
    .concat(availableAddonsEnergyElements)
    .map((el) => {
      const price = getValidPriceForDate(startDate, el.prices || el.pricesByArea?.[area])

      return price
        ? {
            price,
            priceUnit: el.priceUnit || '',
            elementName: el.elementName,
            key: el.key,
          }
        : null
    })
    .filter(isEnergyPriceElement)
}

export const getEnergyPriceElements = (
  contractTemplate: EnrichedContractTemplate,
  selectedAddons: number[],
  startDate?: string,
  area?: PriceAreaCode,
) => {
  if (!area || !startDate) {
    return []
  }

  const contractElements = getContractEnergyPriceElements(contractTemplate, startDate, area)

  const addonElements = getAddonEnergyPriceElements(
    contractTemplate,
    selectedAddons,
    startDate,
    area,
  )

  return contractElements.concat(addonElements)
}

export const getMonthlyFeeElements = (
  contractTemplate: EnrichedContractTemplate,
  selectedAddons: number[],
  startDate?: string,
  area?: PriceAreaCode,
): MonthlyFeeElement[] => {
  if (!area || !startDate) {
    return []
  }

  const getDiscountDetails = (monthlyFeeEl: TariffElement, discount?: ContractDiscountElement) => {
    const monthlyFeePrice = getValidPriceForDate(
      startDate,
      monthlyFeeEl.prices || monthlyFeeEl.pricesByArea?.[area],
    )
    const monthlyFeeDiscountPrice = getValidPriceForDate(
      startDate,
      discount?.prices || discount?.pricesByArea?.[area],
    )

    const priceDetails = [monthlyFeePrice, monthlyFeeDiscountPrice].filter(Boolean)

    return discount && priceDetails.length > 1
      ? {
          priceWithDiscount: {
            priceInclVat: sumPrices(priceDetails, 'priceInclVat'),
            priceExclVat: sumPrices(priceDetails, 'priceExclVat'),
            vatAmount: sumPrices(priceDetails, 'vatAmount'),
            priceUnit: discount.priceUnit,
          },
          discountDuration: discount.duration?.amount,
        }
      : undefined
  }

  const contractElements = contractTemplate.tariffElements.filter((el) =>
    isMonthlyFeeElement(el.type),
  )
  const contractMonthlyFeeElements: MonthlyFeeElement[] = contractElements
    .map((monthlyFeeEl) => {
      const discount = contractTemplate.discountElements.find((discount) =>
        isDiscountForElement(monthlyFeeEl.type, discount.type),
      )

      const discountDetails = getDiscountDetails(monthlyFeeEl, discount)
      const price = getValidPriceForDate(
        startDate,
        monthlyFeeEl.prices || monthlyFeeEl.pricesByArea?.[area],
      )

      if (!price) {
        logError(
          new UiError(`Cannot find valid price for monthly element ${monthlyFeeEl.elementName}`),
        )
        return
      }

      return {
        key: monthlyFeeEl.type,
        elementName: monthlyFeeEl.elementName,
        priceWithoutDiscount: {
          priceInclVat: price?.priceInclVat,
          priceExclVat: price?.priceExclVat,
          vatAmount: price?.vatAmount,
          priceUnit: monthlyFeeEl.priceUnit,
        },
        ...discountDetails,
      }
    })
    .filter(Boolean)

  const addonsMonthlyFeeElements: MonthlyFeeElement[] = [
    ...contractTemplate.bundledAddons,
    ...contractTemplate.availableAddons,
  ]
    .filter((addon) => selectedAddons.includes(addon.tariffNo))
    .map((addon) => {
      const monthlyFeeEl = addon.tariffElements?.find((el) => isMonthlyFeeElement(el.type))

      if (monthlyFeeEl) {
        const discount = addon.discountElements?.find((discount) =>
          isDiscountForElement(monthlyFeeEl.type, discount.type),
        )

        const discountDetails = getDiscountDetails(monthlyFeeEl, discount)
        const price = getValidPriceForDate(
          startDate,
          monthlyFeeEl.prices || monthlyFeeEl.pricesByArea?.[area],
        )

        if (!price) {
          logError(
            new UiError(`Cannot find valid price for monthly element ${monthlyFeeEl.elementName}`),
          )
          return
        }

        return {
          key: getKeyForAddon(addon.addonType, monthlyFeeEl.type),
          elementName: monthlyFeeEl.elementName,
          priceWithoutDiscount: {
            priceInclVat: price?.priceInclVat,
            priceExclVat: price?.priceExclVat,
            vatAmount: price?.vatAmount,
            priceUnit: monthlyFeeEl.priceUnit,
          },
          ...discountDetails,
        }
      }
    })
    .filter(Boolean)

  return contractMonthlyFeeElements.concat(addonsMonthlyFeeElements)
}

const getKeyForAddon = (addonType: string, type: string) => `${addonType}_${type}`

const addKey = (addonType: string, element: TariffElement | DiscountElement) => ({
  ...element,
  key: getKeyForAddon(addonType, element?.type || ''),
})

const isEnergyPriceElement = (item: unknown): item is EnergyPriceElement =>
  Boolean(
    (item as EnergyPriceElement)?.price &&
      (item as EnergyPriceElement)?.key &&
      (item as EnergyPriceElement)?.elementName,
  )

export const calculateMonthlySavings = (cost: ContractTemplateCost | null, isVatIncl?: boolean) => {
  const { estimatedMonthlyCost, estimatedMonthlyCostWithDiscounts } = cost || {}
  const price = isVatIncl ? estimatedMonthlyCost?.priceInclVat : estimatedMonthlyCost?.priceExclVat
  const priceWithDiscount = isVatIncl
    ? estimatedMonthlyCostWithDiscounts?.priceInclVat
    : estimatedMonthlyCostWithDiscounts?.priceExclVat

  if (price !== undefined && priceWithDiscount !== undefined && price !== priceWithDiscount) {
    return price - priceWithDiscount
  }
  return null
}

export const getSelectedAddonsTariffNos = (
  contractTemplate: EnrichedContractTemplate,
  selectedAddons: SelectedAddons,
) => [
  ...contractTemplate.bundledAddons.map((addon) => addon.tariffNo),
  ...Object.values(selectedAddons || {})
    .map((el) => el?.value)
    .filter(Boolean),
]
