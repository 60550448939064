import { DateTime } from 'luxon'
import { useTranslations } from 'next-intl'
import { useMemo, useState } from 'react'

import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import { calculateComparativePrice } from '@/open-web/services/calculators/calculateComparativePrice'
import { DATE_FORMAT_COMPARATIVE_PRICE } from '@/open-web/utils/constants'
import { priceFormat } from '@/open-web/utils/priceFormat'
import { Divider } from '@/shared/components/Divider'
import { SectionError } from '@/shared/components/SectionError'
import { useTheme } from '@/shared/hooks/useTheme'
import { useFullLocale } from '@/shared/locale'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import { useDeliveryInfo } from '@/shared/utils/gtm/gtmHelper'

export type ComparativePriceProps = {
  contractTemplate: EnrichedContractTemplate
}

/**
 * Show comparative price as value for spot priceType contracts.
 * As table for fixed and hybrid priceType contracts.
 */
export const ComparativePrice = ({ contractTemplate }: ComparativePriceProps) => {
  const { estimatedConsumption, deliveryArea } = useDeliveryInfo()
  const fullLocale = useFullLocale()
  const t = useTranslations('purchaseFlow.priceBreakdown.comparisonPrice')
  const theme = useTheme()
  const [isCalculationError, setIsCalculationError] = useState(false)
  const isVatIncl = contractTemplate.customerType === 'PRIVATE'

  const comparativePrice = useMemo(() => {
    try {
      setIsCalculationError(false)
      return calculateComparativePrice({
        contractTemplate,
        area: deliveryArea,
        estimatedYearlyConsumption: estimatedConsumption,
      })
    } catch {
      setIsCalculationError(true)
      return
    }
  }, [deliveryArea, estimatedConsumption, contractTemplate])

  const shouldShowSpotPrice = comparativePrice?.priceType === 'SPOT' && comparativePrice?.cost
  const shouldShowFixedPrice =
    (comparativePrice?.priceType === 'FIXED' || comparativePrice?.priceType === 'HYBRID') &&
    comparativePrice?.periods?.length

  if (isCalculationError) {
    return <SectionError message={t('calculationError')} />
  }

  if (shouldShowSpotPrice) {
    return (
      <Box display="flex" gap={spacing.xxxxs}>
        <ContentText size="l" weight="medium">
          {t('closestUsageLabel', { closestUsage: comparativePrice?.closestUsage })}
        </ContentText>
        <ContentText size="l" weight="medium">
          {priceFormat(comparativePrice.cost, fullLocale, isVatIncl)}
        </ContentText>
      </Box>
    )
  }

  if (shouldShowFixedPrice) {
    return (
      <Box display="flex" flexDirection="column" gap={spacing.xxxs}>
        <ContentText size="l" weight="medium">
          {t('closestUsageLabel', { closestUsage: comparativePrice?.closestUsage })}
        </ContentText>
        <Box display="flex" justifyContent="space-between">
          <ContentText>{t('fixedPriceColumnStartOfDelivery')}</ContentText>
          <ContentText>
            {t('fixedPriceColumnPrice', {
              priceUnit: comparativePrice?.periods[0]?.cost?.priceUnit || '',
            })}
          </ContentText>
        </Box>
        <Divider
          color={{ line: theme.colors.textPrimary }}
          variant="solid"
          verticalSpacing="none"
        />
        {comparativePrice.periods.map((period) => (
          <Box key={period.period} display="flex" justifyContent="space-between">
            <ContentText>
              {DateTime.fromFormat(period.period, DATE_FORMAT_COMPARATIVE_PRICE)
                .toFormat(DATE_FORMAT_COMPARATIVE_PRICE, { locale: fullLocale })
                .replace(/^./, (str) => str.toUpperCase())}
            </ContentText>
            <ContentText>{priceFormat(period.cost, fullLocale, isVatIncl)}</ContentText>
          </Box>
        ))}
      </Box>
    )
  }

  return null
}
