import { DateTime } from 'luxon'

import {
  MONTHS_IN_YEAR,
  calcEstimatedMonthlyCost,
  getContractMonthlyDiscounts,
  sumPricesByType,
} from '@/open-web/services/calculators/utils/utils'
import { DATE_FORMAT, DATE_FORMAT_COMPARATIVE_PRICE } from '@/open-web/utils/constants'
import type { CustomerType, PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import { logError } from '@/shared/utils/error'
import { ContractOrderError } from '@/shared/utils/errorClasses'
import { isEnergyElement, isMonthlyFeeElement } from '@/shared/utils/tariffElementUtils'

import type { CostDetails } from './calculateContractTemplateCost'
import { calculateContractTemplateCost } from './calculateContractTemplateCost'

const COMPARATIVE_USAGE_VALUES_B2C = [2000, 5000, 20_000]
const COMPARATIVE_USAGE_VALUES_B2B = [15_000, 50_000, 75_000]

type CalculateComparativePriceProps = {
  contractTemplate: EnrichedContractTemplate
  area?: PriceAreaCode
  estimatedYearlyConsumption?: number
}

type FixedPeriod = {
  period: string
  cost: CostDetails
}

export type ComparativePriceFixed = {
  priceType: 'FIXED' | 'HYBRID'
  periods: FixedPeriod[]
  closestUsage: number
}

export type ComparativePriceSpot = {
  priceType: 'SPOT'
  cost: CostDetails
  closestUsage: number
}

export type ComparativePrice = ComparativePriceFixed | ComparativePriceSpot

/**
 * Comparative price (comparison price) is average price of 1 kWh.
 * That price should include:
 * - all contract bind costs
 * - all bundled addons costs
 * - all contract discounts
 *
 * It should be calculated for specific usage levels: 2000, 5000, 20000
 * It should be calculated over entire contract duration so eg.:
 * for 3 years fixed contract we should estimate average price for entire contract duration not only for the first year
 */
export const calculateComparativePrice = ({
  contractTemplate,
  area,
  estimatedYearlyConsumption,
}: CalculateComparativePriceProps): ComparativePrice | null => {
  if (!area || !estimatedYearlyConsumption) {
    return null
  }

  const closestUsage = getClosestUsage(estimatedYearlyConsumption, contractTemplate.customerType)
  const { priceType } = contractTemplate

  if (priceType === 'SPOT') {
    return {
      priceType,
      cost: calculateSpotComparativePrice({ contractTemplate, area, closestUsage }),
      closestUsage,
    }
  }
  if (priceType === 'FIXED' || priceType === 'HYBRID') {
    return {
      priceType,
      periods: calculateFixedComparativePrice({ contractTemplate, area, closestUsage }) || [],
      closestUsage,
    }
  }

  throw logError(
    new ContractOrderError(`contractTemplate price type ${priceType} is not implemented`),
  )
}

type CalculateSpotComparativePriceProps = {
  contractTemplate: EnrichedContractTemplate
  area: PriceAreaCode
  closestUsage: number
}

const calculateSpotComparativePrice = ({
  contractTemplate,
  area,
  closestUsage,
}: CalculateSpotComparativePriceProps) => {
  const result = calculateContractTemplateCost({
    contractTemplate,
    area,
    estimatedYearlyConsumption: closestUsage,
    selectedAddonsTariffNos: contractTemplate.bundledAddons.map((el) => el.tariffNo),
  })

  if (result !== null) {
    return getElectricityPriceBaseOnCost(
      closestUsage,
      result.estimatedMonthlyCostWithDiscounts,
      result.energyPriceSum.priceUnit,
    )
  }

  throw logError(new ContractOrderError('Unable to calculate spot comparative price'))
}

type CalculateFixedComparativePriceProps = {
  contractTemplate: EnrichedContractTemplate
  area: PriceAreaCode
  closestUsage: number
}

const calculateFixedComparativePrice = ({
  contractTemplate,
  area,
  closestUsage,
}: CalculateFixedComparativePriceProps): FixedPeriod[] => {
  const { tariffElements, bundledAddons } = contractTemplate
  const periods = tariffElements
    .find((el) => isEnergyElement(el.type))
    ?.pricesByArea?.[area]?.map(({ fromDate, toDate }) => ({ fromDate, toDate }))

  if (!periods) {
    throw logError(new ContractOrderError('Can not find main energy element of the contract'))
  }

  return (
    periods
      //filter past periods, valid case on the beginning of the month when new prices are not, yet updated
      .filter((period) => (period.toDate ? DateTime.fromISO(period.toDate) > DateTime.now() : true))
      .map((period) => {
        const formattedPeriod = DateTime.fromISO(period.fromDate).toFormat(DATE_FORMAT)
        //price for 1 kWh
        const energyPriceSum = sumPricesByType(
          contractTemplate,
          bundledAddons,
          formattedPeriod,
          area,
          isEnergyElement,
        )

        //monthlyFee
        const monthlyFeeSum = sumPricesByType(
          contractTemplate,
          bundledAddons,
          formattedPeriod,
          area,
          isMonthlyFeeElement,
        )

        const contractDiscountsOverDuration = getContractMonthlyDiscounts(
          contractTemplate,
          contractTemplate.validity?.duration?.amount || MONTHS_IN_YEAR,
        )

        //cost of contract over the time of its duration with contract discounts
        const estimatedMonthlyCostWithDiscounts = calcEstimatedMonthlyCost(
          closestUsage,
          energyPriceSum,
          monthlyFeeSum,
          contractDiscountsOverDuration,
        )

        return {
          cost: getElectricityPriceBaseOnCost(
            closestUsage,
            estimatedMonthlyCostWithDiscounts,
            energyPriceSum.priceUnit,
          ),
          period: DateTime.fromISO(period.fromDate).toFormat(DATE_FORMAT_COMPARATIVE_PRICE),
        }
      })
  )
}

const getClosestUsage = (currentUsage: number, customerType: CustomerType) => {
  const values =
    customerType === 'PRIVATE' ? COMPARATIVE_USAGE_VALUES_B2C : COMPARATIVE_USAGE_VALUES_B2B
  const lowestValue = Math.min(...values)

  const closestUsage = values.reduce(
    (prev, curr) => (Math.abs(curr - currentUsage) < Math.abs(prev - currentUsage) ? curr : prev),
    lowestValue,
  )

  return closestUsage
}

const getElectricityPriceBaseOnCost = (
  closestYearlyUsage: number,
  cost: CostDetails,
  priceUnit?: string | null,
): CostDetails => {
  const calc = (price: number) => (price * 100) / (closestYearlyUsage / MONTHS_IN_YEAR)
  const { priceExclVat, priceInclVat, vatAmount } = cost

  return {
    priceUnit,
    priceExclVat: calc(priceExclVat),
    priceInclVat: calc(priceInclVat),
    vatAmount: calc(vatAmount),
  }
}
