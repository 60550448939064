import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import type { CustomerType } from '@/shared/store/slices/selectedContractSlice'

import { getSelectedAddonsTariffNos } from '../../PriceBreakdown/utils'

export const useSelectedAddonsTariffNos = ({
  contractTemplate,
}: {
  contractTemplate?: EnrichedContractTemplate
}) => {
  const selectedContract = useGlobalStore((state) => state.selectedContract)

  // If contractTemplate is not defined, return empty array for selectedAddonsTariffNos
  if (!contractTemplate) {
    return {
      selectedAddonsTariffNos: [],
    }
  }

  const customerType = contractTemplate.customerType.toLocaleLowerCase() as CustomerType

  const { selectedAddons } = selectedContract[customerType]

  const selectedAddonsTariffNos = contractTemplate
    ? getSelectedAddonsTariffNos(contractTemplate, selectedAddons)
    : []

  return {
    selectedAddonsTariffNos,
  }
}
